import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import {
  DtImportAddPresentedDocument,
  DtImportAddWareItem,
  DtImportDeleteWareItems,
  DtImportReplacePresentedDocuments,
  DtImportReplaceWares,
  DtImportReplaceWaresAndDocuments,
  DtImportUpdateBlock,
  DtImportUpdatePresentedDocuments,
  DtImportUpdateWareItems,
  SetDtImportDocument,
} from './dt-import.actions';
import { DtStateModel } from '../types/dt-import';

@State<DtStateModel>({
  name: 'dt',
  defaults: {
    selectedDocument: null,
    selectedDocumentWares: [],
  },
})
@Injectable()
export class DtImportState {
  // TODO: сделать селекторы на каждый блок в отдельности для оптимизации
  @Selector()
  static getSelectedDocument(state: DtStateModel) {
    return state.selectedDocument;
  }

  @Selector()
  static getSelectedDocumentWares(state: DtStateModel) {
    return state.selectedDocumentWares;
  }

  @Action(DtImportUpdateBlock)
  statisticsUpdateBlock(
    ctx: StateContext<DtStateModel>,
    action: DtImportUpdateBlock,
  ) {
    const [key] = Object.keys(action.block);

    ctx.setState(
      produce(draft => {
        if (draft.selectedDocument) {
          draft.selectedDocument[key] = action.block[key];
        }
      }),
    );
  }

  @Action(DtImportAddPresentedDocument)
  statisticsAddPresentedDocument(
    ctx: StateContext<DtStateModel>,
    action: DtImportAddPresentedDocument,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocument?.presentedDocuments.push(
          ...action.presentedDocumentItem,
        );
      }),
    );
  }

  @Action(DtImportUpdatePresentedDocuments)
  statisticsUpdatePresentedDocuments(
    ctx: StateContext<DtStateModel>,
    action: DtImportUpdatePresentedDocuments,
  ) {
    ctx.setState(
      produce(draft => {
        action.presentedDocuments.forEach(document => {
          const id = document.id;
          const index = ctx
            .getState()
            .selectedDocument?.presentedDocuments.findIndex(i => i.id === id);
          if (index !== null && index !== undefined) {
            draft.selectedDocument?.presentedDocuments.splice(
              index,
              1,
              document,
            );
          }
        });
      }),
    );
  }

  @Action(DtImportReplacePresentedDocuments)
  statisticsReplacePresentedDocuments(
    ctx: StateContext<DtStateModel>,
    action: DtImportReplacePresentedDocuments,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocument?.presentedDocuments.splice(0);
        draft.selectedDocument?.presentedDocuments.push(
          ...action.presentedDocuments,
        );
      }),
    );
  }

  @Action(SetDtImportDocument)
  setDtImportDocument(
    ctx: StateContext<DtStateModel>,
    action: SetDtImportDocument,
  ) {
    const { wares, ...other } = action.declaration;
    ctx.patchState({
      selectedDocumentWares: wares,
      selectedDocument: other,
    });
  }

  @Action(DtImportAddWareItem)
  statisticsAddWareItem(
    ctx: StateContext<DtStateModel>,
    action: DtImportAddWareItem,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocumentWares?.push(action.wareItem);
      }),
    );
  }

  @Action(DtImportUpdateWareItems)
  statisticsUpdateWareItems(
    ctx: StateContext<DtStateModel>,
    action: DtImportUpdateWareItems,
  ) {
    ctx.setState(
      produce(draft => {
        action.wareItems.forEach(wareItem => {
          const id = wareItem.id;
          const index = ctx
            .getState()
            .selectedDocumentWares.findIndex(i => i.id === id);
          if (index !== null) {
            draft.selectedDocumentWares.splice(index, 1, wareItem);
          }
        });
      }),
    );
  }

  @Action(DtImportDeleteWareItems)
  statisticsDeleteWareItems(
    ctx: StateContext<DtStateModel>,
    action: DtImportDeleteWareItems,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocument?.presentedDocuments.splice(0);
        draft.selectedDocument?.presentedDocuments.push(
          ...action.documentsAndWares.presentedDocuments,
        );
        draft.selectedDocumentWares.splice(0);
        draft.selectedDocumentWares.push(...action.documentsAndWares.wares);
      }),
    );
  }

  @Action(DtImportReplaceWaresAndDocuments)
  statisticsReplaceWaresAndDocuments(
    ctx: StateContext<DtStateModel>,
    action: DtImportReplaceWaresAndDocuments,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocument?.presentedDocuments.splice(0);
        draft.selectedDocument?.presentedDocuments.push(
          ...action.documentsAndWares.presentedDocuments,
        );
        draft.selectedDocumentWares.splice(0);
        draft.selectedDocumentWares.push(...action.documentsAndWares.wares);
      }),
    );
  }

  @Action(DtImportReplaceWares)
  statisticsReplaceWares(
    ctx: StateContext<DtStateModel>,
    action: DtImportReplaceWares,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocumentWares = action.wareItems;
      }),
    );
  }
}
